import { ID_CART } from '@/src/constants'
import { GET_API, POST_API, PUT_API } from '../configApi'
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const URL_CART = 'admin/com_api/api/checkouts_v2/session'

const PAYMENT_HIND = 'paymentHint'
export const getCart = async (): Promise<any> => {
  const idCart = window.localStorage.getItem(ID_CART)?.replace(/"/g, '') as string
  return await GET_API({ endpoint: `${URL_CART}/${idCart}` })
}

export const addCart = async (cartData: any): Promise<any> => {
  return await POST_API({ endpoint: URL_CART, data: cartData })
}

export const updateCart = async (idCart: string, payload: any): Promise<any> => {
  return await PUT_API({ endpoint: `${URL_CART}/${idCart}`, data: payload })
}

export const addPaymeCart = async (
  idCart: string,
  paymentMethodId: number,
  amount: number,
  method: string
): Promise<any> => {
  return await POST_API({
    endpoint: `admin/com_api/api/checkouts_v2/pay/${idCart}/${paymentMethodId}/?amount=${amount}&${PAYMENT_HIND}=${method}`
  })
}

export const confirmPaymeCart = async (): Promise<any> => {
  const startTime = Date.now() // Lưu thời gian bắt đầu
  const TIMEOUT_DURATION = 60 * 1000 // Giới hạn 1 phút

  while (true) {
    const idCart = window.localStorage.getItem(ID_CART)?.replace(/"/g, '') as string

    const response = await GET_API({ endpoint: `${URL_CART}/${idCart}` }) // Gọi API để kiểm tra giỏ hàng
    if (!response.is_error && response?.data?.order?.id && response?.data?.order?.paymentStatus === 'Paid') {
      return response?.data?.order // Nếu giỏ hàng được thanh toán, trả về thông tin giỏ hàng
    }

    // Kiểm tra nếu đã quá 1 phút
    if (Date.now() - startTime >= TIMEOUT_DURATION) {
      return null // Hết thời gian 1 phút, trả về null
    }

    await delay(2000) // Tạm dừng 2 giây giữa các lần gọi API
  }
}

export const submitCart = async (payload: any): Promise<any> => {
  const idCart = window.localStorage.getItem(ID_CART)?.replace(/"/g, '') as string
  return await POST_API({ endpoint: `${URL_CART}/${idCart}`, data: payload })
}
