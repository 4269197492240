import { updateDeviceCode } from '@/src/apis/'
import { Header } from '@/src/components'
import { APP_INFO, PRINT_SAMPLE, SETTINGS, TOKEN, VERSION_APP, WHITE_LIST_DEV_MODE } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { decodeToken, printHTML, registerPrinter, useLocalStorage } from '@/src/utils'
import { scanner } from '@/src/utils/rfid'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Settings: React.FC = () => {
  return (
    <div className='layout pb-40'>
      <Header title='settings.setting' />
      <Body />
      <div className='h-28' />
    </div>
  )
}

//viewChildren
const Body: React.FC<any> = () => {
  const [token, setToken] = useLocalStorage<IAppInfoLocalStorage | string>(TOKEN, '')
  // @ts-ignore
  const [appInfo, setAppInfo, clearValue, clearAllValue] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const htmlRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const onClick = async (link: string) => {
    navigate(link)
  }

  const mapToValue = (key: string): string => {
    const { deviceCode, location, paymeCode, higd } = appInfo ?? {}

    if (key === 'DEVICE CODE') {
      return deviceCode ?? ''
    }

    if (key === 'LOCATIONS') {
      if (!location) return ''
      const { countryName, provinceName, districtName, wardName, address, name, phone, email } = location
      const parts = [countryName, provinceName, districtName, wardName, address, name, phone, email].filter(
        (part) => part
      )

      return parts.join(' - ') // Nối các phần lại với nhau bằng dấu " - "
    }

    if (key === 'PAYME CODE') {
      return paymeCode ?? ''
    }

    if (key === 'HiGD') {
      if (!higd) {
        return ''
      }

      const { ip_1, ip_2 } = higd
      return [ip_1, ip_2].filter((ip) => ip?.trim() !== '').join(',')
    }

    return ''
  }

  const onLogout = () => {
    // clearAllValue()
    setToken('')
    navigate('/admin')
  }

  const onPrintTest = async () => {
    if (htmlRef.current === null) return

    const dataPrint = htmlRef.current
    dataPrint.classList.remove('absolute')
    dataPrint.classList.remove('-z-10')

    await printHTML(dataPrint)
  }

  const CONFIGS = [
    {
      id: 0,
      title: `${t('settings.rfid').toLocaleUpperCase()}`,
      onClick: async () => {
        const device = await scanner.pairDevice()
        if (device) {
          setAppInfo({
            ...appInfo,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          } as IAppInfoLocalStorage)

          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            rfid: { vendorId: device.usbVendorId, productId: device.usbProductId }
          })
        }
      }
    },
    {
      id: 1,
      title: `${t('settings.printer').toLocaleUpperCase()}`,

      onClick: async () => {
        const device = await registerPrinter()
        if (device) {
          await updateDeviceCode({
            device_code: appInfo?.deviceCode,
            printer: { vendorId: device.vendorId, productId: device.productId }
          })
        }
      }
    },
    {
      id: 2,
      title: `${t('settings.logOut').toLocaleUpperCase()}`,
      onClick: () => onLogout()
    },
    {
      id: 3,
      title: `${t('settings.restart').toLocaleUpperCase()}`,
      onClick: () => {
        window.location.reload()
      }
    },
    {
      id: 3,
      title: `${t('settings.testPrint').toLocaleUpperCase()}`,
      onClick: () => {
        onPrintTest()
      }
    }
    // {
    //   id: 3,
    //   title: `${t('settings.rfidTool').toLocaleUpperCase()}`,
    //   onClick: () => navigate('/settings-rfid-tool')
    // }
  ]

  const isDisbledSetting = useMemo(() => {
    return appInfo?.deviceCode ? false : true
  }, [appInfo])

  const isDevMode = useMemo(() => {
    if (!token) return false
    const decoded = decodeToken(token as string)

    if (WHITE_LIST_DEV_MODE.includes(decoded?.orgid)) return true
    return false
  }, [token])

  return (
    <div className={'flex flex-col h-screen w-screen items-center pt-20 gap-5 mb-20 bg-white'}>
      {SETTINGS?.map((val, index) => {
        const isDisabledButton = val?.key === 'deviceCode' ? false : isDisbledSetting
        return (
          <div className={'w-3/5'} key={index}>
            <p className='responsive-text-small'>{t(`settings.${val.key}`)}</p>

            <div className='flex justify-center imtems-center mt-4'>
              <div className='w-full min-h-28 px-2.5 border-2  rounded-lg flex items-center'>
                <p className={'text-[#9ca3af] responsive-text-small-2 break-all'}>{mapToValue(val.title)}</p>
              </div>
              <button
                disabled={isDisabledButton}
                onClick={() => onClick(val.link)}
                className={`${isDisabledButton ? 'btn-disabled' : 'btn-custom'}  background-accent700 h-28 w-1/3 rounded-lg ml-10`}
              >
                <p className={'responsive-text-small-2 text-white'}>{t(`settings.edit`)}</p>
              </button>
            </div>
          </div>
        )
      })}

      <div className='flex w-3/5 mt-10 flex-wrap justify-between gap-y-8'>
        {CONFIGS?.map((val, index) => {
          return (
            <button
              key={index}
              onClick={() => val.onClick()}
              className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}
            >
              <p className={'responsive-text-small-2 text-white'}>{val.title}</p>
            </button>
          )
        })}

        {isDevMode && <DEV_MODE />}
      </div>

      <p className='mt-20 italic'>Version {VERSION_APP}</p>

      <div className='absolute -top-[200rem] -z-10'>
        <div className='w-[550px] pr-5' ref={htmlRef} dangerouslySetInnerHTML={{ __html: PRINT_SAMPLE }} />
      </div>
    </div>
  )
}

//view
const DEV_MODE = () => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/settings-dev-mode')
  }

  return (
    <button onClick={onClick} className={`btn-custom background-accent700 h-28 w-[30%] rounded-lg`}>
      <p className={'responsive-text-small-2 text-white'}>DEV MODE</p>
    </button>
  )
}

export default Settings
