import { GET_API } from '../configApi'
const URL_PRODUCT = 'admin/com_api/api/products'
export const filterByBarcode = async (barcode: Array<string>): Promise<any> => {
  // note nhờ QC test add nhiều sp , chia ra search nhiều lần.
  // 2 sp cùng barcode => lấy sp đâu tiên **confirm BA**
  // 2 variant cùng barcode => lấy variant đâu tiên **confirm BA**
  // lấy variantID tìm thấy
  const query = encodeURIComponent(`filter=(${barcode.map((i) => `(barcode:product=${i})`).join('||')})`)
  return await GET_API({ endpoint: `${URL_PRODUCT}?query=${query}` })
}
