import Home from '@/src/routers/home'
import { Route, Routes } from 'react-router-dom'
import Admin from '@/src/routers/admin'
//SETTINGS
import Settings from '@/src/routers/settings'
import DeviceCode from '@/src/routers/settings/deviceCode'
import Location from '@/src/routers/settings/location'
import Payme from '@/src/routers/settings/paymeCode'
import Language from '@/src/routers/settings/languages'
import HiGD from '@/src/routers/settings/higd'
import DevMode from '@/src/routers/settings/devMode'
// AUTHEN
import Login from '@/src/routers/auth/login'
import LoginEmail from '@/src/routers/auth/loginEmail'
import LoginPhone from '@/src/routers/auth/loginPhone'
import Register from '@/src/routers/auth/register'
// PAYMENT
import Shop from '@/src/routers/shop'
import Cart from '@/src/routers/cart'
import PaymentInvoice from '@/src/routers/payment/invoice'
import PaymentMethod from '@/src/routers/payment/method'
import PaymentMethodDetail from '@/src/routers/payment/methodDetail'
import PaymentCompleted from '@/src/routers/payment/completed'
function App() {
  return (
    <Routes>
      <Route path='/home' element={<Home />} />
      {/* ADMIN */}
      <Route path='/admin' element={<Admin />} />
      <Route path='/admin/auth/login/callback' element={<Admin />} />
      {/* AUTHEN */}
      <Route path='/login' element={<Login />} />
      <Route path='/login-email' element={<LoginEmail />} />
      <Route path='/login-phone' element={<LoginPhone />} />
      <Route path='/register' element={<Register />} />
      {/* PAYMENT */}
      <Route path='/shop' element={<Shop />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/payment-invoice' element={<PaymentInvoice />} />
      <Route path='/payment-method' element={<PaymentMethod />} />
      <Route path='/payment-method/:methodId' element={<PaymentMethodDetail />} />
      <Route path='/payment-completed' element={<PaymentCompleted />} />
      {/* SETTINGS */}
      <Route path='/settings' element={<Settings />} />
      <Route path='/settings-device-code' element={<DeviceCode />} />
      <Route path='/settings-location' element={<Location />} />
      <Route path='/settings-payme' element={<Payme />} />
      <Route path='/settings-language' element={<Language />} />
      <Route path='/settings-higd' element={<HiGD />} />
      <Route path='/settings-dev-mode' element={<DevMode />} />
    </Routes>
  )
}

export default App
