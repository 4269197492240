import { getCart } from '@/src/apis'
import { APP_INFO, TOKEN } from '@/src/constants'
import { AppContextType, IAppInfoLocalStorage, ProviderProps } from '@/src/types'
import { isTokenExpired, useInactivityMonitor, useLocalStorage } from '@/src/utils'
import i18next from 'i18next'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
const AuthContext = createContext<AppContextType | undefined>(undefined)
export const AppProvider: React.FC<ProviderProps> = ({ children }) => {
    // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const [token, setToken] = useLocalStorage<any>(TOKEN, null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cart, setCart] = useState<any>(null)
  const [order, setOrder] = useState<any>(null)

  const navigate = useNavigate()

  useInactivityMonitor()

  // SIDE EFFECTS

  useEffect(() => {
    if (process.env.NODE_ENV != 'production') return

    // PREVENT RIGHT CLICK / OPEN DEVTOOLS
    const handleContextMenu = (e: MouseEvent) => e.preventDefault()
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && ['I', 'J', 'C'].includes(e.key))) {
        e.preventDefault()
      }
      if ((e.ctrlKey || e.metaKey) && (e.key === '+' || e.key === '-')) {
        /* Ngăn thao tác zoom bằng phím */
        e.preventDefault()
      }
    }

    // DISABLE TOUCH ZOOM (Pinch-to-zoom, swipe, etc.)
    const handleTouchMove = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault() // Ngăn pinch-to-zoom
      }
    }

    // AUTO FULLSCREEN
    const enterFullscreen = () => {
      const elem = document.documentElement as HTMLElement & {
        mozRequestFullScreen?: () => Promise<void>
        webkitRequestFullscreen?: () => Promise<void>
        msRequestFullscreen?: () => Promise<void>
      }

      if (elem.requestFullscreen) {
        elem.requestFullscreen().catch((err) => {
          console.error('Failed to enter fullscreen mode:', err)
        })
      } else if (elem.mozRequestFullScreen) {
        // Firefox
        elem.mozRequestFullScreen().catch((err) => {
          console.error('Failed to enter fullscreen mode:', err)
        })
      } else if (elem.webkitRequestFullscreen) {
        // Chrome, Safari & Opera
        elem.webkitRequestFullscreen().catch((err) => {
          console.error('Failed to enter fullscreen mode:', err)
        })
      } else if (elem.msRequestFullscreen) {
        // IE/Edge
        elem.msRequestFullscreen().catch((err) => {
          console.error('Failed to enter fullscreen mode:', err)
        })
      }
    }

    // Apply styles to disable touch actions
    // document.body.style.touchAction = 'none'

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu)
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('touchmove', handleTouchMove, { passive: false }) // Ngăn chặn pinch-to-zoom trên các thiết bị cảm ứng

    // Enter fullscreen on the first user interaction
    document.addEventListener('click', enterFullscreen, { once: true })

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('touchmove', handleTouchMove)
      // document.body.style.touchAction = '' // Reset lại giá trị touchAction
    }
  }, [])

  useEffect(() => {
    if (token) {
      if (!isTokenExpired(token)) {
        navigate('/home')
      } else {
        navigate('/admin')
      }
    } else {
      navigate('/admin')
    }
  }, [token])

  useEffect(() => {
    i18next.changeLanguage(appInfo?.language || 'en')
  }, [appInfo?.language])

  // UTILS

  const onSetCart = async () => {
    const res = await getCart()

    if (!res?.is_error) setCart(res?.data)
  }

  const onClearCart = () => {
    setCart(null)
  }
  const onLogout = () => {
    setToken(null)
    // window.localStorage.removeItem(TOKEN)
    navigate('/admin', { replace: true })
  }

  const onLoading = (bool: boolean) => {
    setIsLoading(bool)
  }

  const onSetOrder = (data: any) => {
    setOrder(data)
  }

  const value = useMemo(
    () => ({
      token,
      isLoading,
      cart,
      order,
      setCart,
      onSetCart,
      onLogout,
      onLoading,
      onClearCart,
      onSetOrder
    }),
    [token, isLoading, cart, order]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
export function useApp(): AppContextType {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useApp must be used within an AppProvider')
  }
  return context
}
