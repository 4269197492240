export const PRINT_SAMPLE = `
<html>
    <head> </head>
    <body>
        <style>
            body {
                font-family: Arial, Helvetica, sans-serif;
                margin: 0;
                padding: 0;
                /*width: 80mm;*/
                font-weight: 500;
                font-size: 22px;
            }

            .invoice {
                /* width: 80mm;
                padding: 0; */
                box-sizing: border-box;
                position: relative;
                margin: 0 auto;
                width: 100%;
            }

            .invoice-header {
                width: 100%;
                margin-bottom: 10px;
                padding: 10px 4px;
                border-bottom: 1px dashed #000;
            }

            .invoice-header p {
                margin: 2px 0;
            }

            .invoice-header .store-info {
                text-align: left;
            }

            .invoice-header .company-info {
                text-align: center;
                margin-bottom: 10px;
            }

            .invoice-header .company-info h2 {
                margin: 0 0 4px;
                font-size: 24px;
                text-transform: uppercase;
            }
            .invoice-header .company-info h3 {
                margin: 0 0 4px;
                font-size: 24px;
            }

            .invoice-body {
                width: 100%;
                margin-bottom: 10px;
            }

            .invoice-body .title {
                text-align: center;
                padding-bottom: 10px;
                margin-bottom: 10px;
                border-bottom: 1px dashed #000;
                font-size: 24px;
            }

            .invoice-body .header-info {
                padding: 0 4px 10px;
                border-bottom: 1px dashed #000;
            }

            .invoice-body .header-info p b ~ b {
                margin-left: 24px;
            }

            .invoice-body .header-info p .label_1 {
                min-width: 100px;
                display: inline-block;
            }

            .invoice-body .header-info p .label_1 ~ span {
                text-transform: uppercase;
            }

            .invoice-body table {
                width: 100%;
                border-collapse: collapse;
            }

            .invoice-body th,
            .invoice-body td {
                padding: 4px !important;
                text-align: left;
            }

            .invoice-body table tr + tr td {
                border: none;
            }

            .invoice-items thead tr th {
                padding: 0 4px 10px;
                border-bottom: 1px dashed #000;
            }

            /*.invoice-items tbody tr:nth-child(3n) td {
        border-bottom: 1px dashed #000;
    }*/

            .invoice-items td.pd-10 {
                padding-left: 10px;
            }

            .invoice-items .left-col {
                width: 40%;
                text-align: right;
                padding-right: 5px;
            }

            .invoice-items .right-col {
                width: 60%;
            }

            .invoice-items th {
                text-align: center;
            }

            .invoice-items td.tex-right {
                text-align: right;
            }

            .invoice-items table tfoot td {
                border-bottom: 1px solid #ddd;
            }

            .invoice-items table tfoot td:last-child,
            .invoice-items table tbody td:last-child {
                text-align: right;
            }

            .invoice-payment .left-col {
                width: 50%;
                text-align: left;
                padding-right: 5px;
            }

            .invoice-payment .right-col {
                width: 50%;
            }

            .invoice-total .left-col {
                width: 40%;
                text-align: left;
                padding-right: 5px;
            }

            .invoice-total .right-col {
                width: 60%;
            }

            .invoice-total .table-1 tfoot tr td {
                border-bottom: 1px dashed #000;
            }

            .invoice-total .table-3 tfoot tr:nth-child(1) td {
                border-top: 1px dashed #000;
            }

            .invoice-payment {
                margin-top: 15px;
            }

            .invoice-payment .right-col {
                text-align: right;
            }

            .invoice-payment table td {
                padding-top: 2px;
            }

            .einvoice {
                width: 60%;
                position: absolute;
                right: 0;
                font-weight: bold;
            }

            .tax-dept-info {
                margin-top: 10px;
            }

            .tax-dept-info p,
            .header-info p {
                margin: 2px 0;
            }

            .w-50 {
                display: inline-block;
                width: 50%;
            }

            .invoice-items .text-left {
                width: 40%;
                text-align: left !important;
            }

            .invoice-items .text-center {
                text-align: center !important;
            }

            .invoice-items .w-60 {
                width: 60%;
                display: table;
                text-align: left;
            }

            .invoice-items .w-60 p {
                margin: 0;
                display: table-cell;
            }

            .invoice-items .w-60 p:last-child {
                text-align: right;
            }

            .invoice-items .w-40 {
                width: 40%;
                padding-left: 10px;
            }

            .invoice-footer {
                width: 100%;
                text-align: center;
                padding-bottom: 10px;
            }

            .invoice-footer img {
                max-width: 100%;
            }

            .invoice-footer .app {
                padding: 0 4px 10px;
                border-bottom: 1px dashed #000;
                margin-bottom: 10px;
            }

            .invoice-footer .note,
            .invoice-footer .barcode {
                padding: 0 4px;
            }

            .invoice-footer .barcode-img {
                padding: 10px 0;
                display: flex;
                justify-content: center;
            }

            .invoice-footer .barcode-img img {
                width: 70%;
            }
            .invoice-logo {
            	text-align: center;
            	margin-bottom: 15px 0;
            }
            .invoice-logo img {
              width: 200px;
              display: inline-block;
            }
        </style>
        <div id="printOrder" class="printorder">
            <div class="invoice">
            	<div class="invoice-logo">
            		<img src="https://file.hstatic.net/1000284478/file/maison_ecom_avatar_4c8ff9b1a44543e6ac98887915a2c0cc.png" alt=""/>
            	</div>
                <div class="invoice-header">
                    <div class="company-info">
                        <h2><b>Test 1_1021</b></h2>
                        <h3><b>MAISON RMI GROUP</b></h3>
                        <!--
                <p>Mã số thuế: 0313175103</p>
                <p>189-197 Dương Bá Trạc, Phường 1, Quận 8, Thành phố Hồ Chí Minh, Việt Nam</p>
                -->
                    </div>

                    <div class="store-info">
                        <!-- <p><b>Tel:</b> 028 3936 9426</p>
                <p><b>Tên cửa hàng:</b> Test 1_1021</p>-->
                        <p><b>Địa chỉ:</b> CHARLES & KEITH NGUYỄN TRÃI - 18-20 Nguyễn Trãi, Phường Bến Thành, Quận 1, Hồ Chí Minh</p>
                        <p><b>Số điện thoại:</b> 02839369426</p>
                        <!-- <p><b>Email:</b> charleskeith01@maisonrmi.com</p>-->
                    </div>
                </div>
                <div class="invoice-body">
                    <h2 class="title"><b>DUPLICATE INVOICE</b></h2>
                    <div class="header-info">
                        <p><b class="label">INVOICE NO. :</b> <span>102101000247</span></p>
                        <p><b class="label">DATE TIME :</b> <span></span> <span>08/10/2024 12:05:31</span></p>
                        <p><b class="label label_1">CUSTOMER ID :</b> 1085005116</p>
                        <p><b class="label label_1">NAME :</b>Lôi Thị Tú Trân</p>
                    </div>
                    <div class="invoice-items">
                        <table>
                            <thead>
                                <tr>
                                    <th style="text-align: left;">ITEM</th>
                                    <th>QTY</th>
                                    <th>PRICE</th>
                                    <th style="text-align: right">AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                 
                                 
                                
                                <tr>
                                    <td class="text-left" style="word-break: break-all">CK1-60361210-2_01_34</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">0</td>
                                    <td class="text-right">0</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-left">
                                        01-34
                                    </td>
                                </tr>
                                 <tr>
                                    <td colspan="4" class="text-left">
                                       Giày cao gót, 01, 34
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td colspan="2" class="text-left">
                                         
                                        LINEDISCOUNT 
                                        
                                    </td>
                                    <td colspan="2" class="text-right">   -1,350,000 
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-left">
                                        ITEM TOTAL :
                                    </td>
                                    <td colspan="2" class="text-right">
                                          0
                                    </td>
                                </tr>
                                
                                <tr>
                                  <td colspan="2" class="text-left" style="border-bottom: 1px dashed #000; padding-bottom: 10px;">FULL PRICE</td>
                                  <td colspan="2" class="text-right" style="border-bottom: 1px dashed #000; padding-bottom: 10px;">
                                    1,350,000
                                  </td>
                                </tr>
                                  
                                
                                <tr>
                                    <td class="text-left" style="word-break: break-all">CK1-60361210-2_01_34</td>
                                    <td class="text-center">3</td>
                                    <td class="text-center">1,350,000</td>
                                    <td class="text-right">4,050,000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-left">
                                        01-34
                                    </td>
                                </tr>
                                 <tr>
                                    <td colspan="4" class="text-left">
                                       Giày cao gót, 01, 34
                                    </td>
                                </tr>
                                
                                <tr>
                                  <td colspan="2" class="text-left" style="border-bottom: 1px dashed #000; padding-bottom: 10px;">FULL PRICE</td>
                                  <td colspan="2" class="text-right" style="border-bottom: 1px dashed #000; padding-bottom: 10px;">
                                    4,050,000
                                  </td>
                                </tr>
                                  
                                
                                <tr>
                                    <td class="text-left" style="word-break: break-all">CK1-60361329_NUDE_35</td>
                                    <td class="text-center">1</td>
                                    <td class="text-center">1,400,000</td>
                                    <td class="text-right">1,400,000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-left">
                                        NUDE-35
                                    </td>
                                </tr>
                                 <tr>
                                    <td colspan="4" class="text-left">
                                       Dép, NUDE, 35
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td colspan="2" class="text-left">
                                         
                                        1spGiam90k 
                                        
                                    </td>
                                    <td colspan="2" class="text-right">   -90,000 
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-left">
                                        ITEM TOTAL :
                                    </td>
                                    <td colspan="2" class="text-right">
                                          1,400,000
                                    </td>
                                </tr>
                                
                                <tr>
                                  <td colspan="2" class="text-left" style="border-bottom: 1px dashed #000; padding-bottom: 10px;">FULL PRICE</td>
                                  <td colspan="2" class="text-right" style="border-bottom: 1px dashed #000; padding-bottom: 10px;">
                                    1,490,000
                                  </td>
                                </tr>
                                 
                            </tbody>
                        </table>
                    </div>
                    <div class="invoice-total">
                        <table class="table-1" cellspacing="0" cellpadding="0">
                            <tfoot>
                                
                                <tr>
                                    <td class="left-col" style="text-align: left;">LOYALTY COIN:</td>
                                    <td class="right-col" style="text-align: right;">
                                        5,000
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td class="left-col" style="text-align: left;">GROSS TOTAL:</td>
                                    <td class="right-col" style="text-align: right;">5,450,000</td>
                                </tr>
                                <tr>
                                    <td class="left-col" style="text-align: left;">NET TOTAL:</td>
                                    <td class="right-col" style="text-align: right;">5,445,000</td>
                                </tr>
                            </tfoot>
                        </table>

                        <table class="table-2" cellspacing="0" cellpadding="0">
                            <tfoot>
                                    
                                <tr>
                                    <td colspan="2" style="text-align: left;">
                                         CASH: 
                                    </td>
                                    <td style="text-align: center;">VND</td>
                                    <td style="text-align: right;">5,445,000</td>
                                </tr>
                                  
                                <tr>
                                    <td colspan="2" style="text-align: left; border-top: 1px dashed #000;">NUMBER OF ITEMS:</td>
                                    <td style="text-align: center; border-top: 1px dashed #000;"></td>
                                    <td style="text-align: right; border-top: 1px dashed #000;">
                                        3
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style="text-align: left; border-bottom: 1px dashed #000;">TOTAL ITEMS:</td>
                                    <td style="text-align: center; border-bottom: 1px dashed #000;"></td>
                                    <td style="text-align: right; border-bottom: 1px dashed #000;">5</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="invoice-footer">
                    <div class="app"><img src="https://file.hara.vn/200000014460/file/untitled-1.jpg?v=1724923192" alt="" srcset="" /></div>
                    <div class="note">
                        Đổi hàng nguyên giá trong 30 ngày kể từ ngày mua theo chính sách Maison. Không hoàn tiền. Chi tiết điều khoản áp dụng vui lòng xem tại:
                        <a href="https://maisonrmi.com/faqs">https://maisonrmi.com/faqs/</a>
                    </div>
                    <div class="barcode">
                        <div class="barcode-img"><img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANwAAAA8CAYAAAANHtQDAAAABHNCSVQICAgIfAhkiAAAAQNJREFUeJzt00kOwjAQAEHM//9sTpHAEnJY0qeqWxbbE0U95pzztjHGeLk+lhz31y3W99d1u33X5+9GPHv+Ou/Zub6dd3fu1euufn72v6x237WbZ93/23l+nfvTeZ/dt28AfyM4CAkOQoKDkOAgJDgICQ5CgoOQ4CAkOAgJDkKCg5DgICQ4CAkOQoKDkOAgJDgICQ5CgoOQ4CAkOAgJDkKCg5DgICQ4CAkOQoKDkOAgJDgICQ5CgoOQ4CAkOAgJDkKCg5DgICQ4CAkOQoKDkOAgJDgICQ5CgoOQ4CAkOAgJDkKCg5DgICQ4CAkOQoKDkOAgJDgICQ5CgoOQ4CAkOAgJDkIPD31YdHHglfUAAAAASUVORK5CYII=' /></div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</html>
`