import { APP_INFO } from '@/src/constants'
import { IAppInfoLocalStorage } from '@/src/types'
import { receiptImage } from '@haravan/escpos'
import html2canvas from 'html2canvas'
import { showToast } from '../helper'
import { getAppInfoFromLocalStorage } from '@/src/utils'

export const registerPrinter = async () => {
  const appInfo: IAppInfoLocalStorage | null = getAppInfoFromLocalStorage()

  const device = await (navigator as any).usb.requestDevice({ filters: [] })

  const updatedAppInfo = {
    ...appInfo,
    printer: { vendorId: device.vendorId, productId: device.productId }
  }
  window.localStorage.setItem(APP_INFO, JSON.stringify(updatedAppInfo))

  return device
}

export const findPrinter = async (): Promise<any | null> => {
  const appInfo: IAppInfoLocalStorage | null = getAppInfoFromLocalStorage()

  if (appInfo?.printer) {
    const { vendorId, productId } = appInfo?.printer
    const devices = await (navigator as any).usb.getDevices()
    return devices.find((device: any) => device.vendorId === vendorId && device.productId === productId)
  }

  const device = registerPrinter()
  return device
}

export const printHTML = async (receiptElement: HTMLElement): Promise<void> => {
  try {
    if (!receiptElement) return

    const printer = await findPrinter()

    if (!printer) return

    const canvas = await html2canvas(receiptElement, {
      allowTaint: true,
      useCORS: true,
      scale: 1 // scale the canvas to 2x the original size
    })
    const imageDataUrl = canvas.toDataURL('image/png')

    const imageData = imageDataUrl.replace(/^data:image\/(png|jpg);base64,/, '')
    const printData = new Uint8Array(await receiptImage({ model: '80' }, imageData))

    await connectAndPrint(printer, printData)
  } catch (err: any) {
    console.log(err)
    showToast('warn', 'Printer error !')
  }
}

const connectAndPrint = async (printer: any, printData: Uint8Array): Promise<void> => {
  try {
    await printer.open();
    await printer.selectConfiguration(1);
    await printer.claimInterface(0);

    // await printer.transferOut(1, printData); // DEV
    await printer.transferOut(import.meta.env.VITE_PRINTER_CONFIG as number, printData); // PROD

    await printer.releaseInterface(0);
    await printer.close();
    console.log('Print successful');
  } catch (err: any) {
    console.log(err);
    showToast('warn', err.toString());
  }
};