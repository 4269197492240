import { alpha, caps, remove } from '@/src/assets'
import {
  KEY_BOARD_TEXT_TYPE_2,
  KEY_BOARD_TEXT_TYPE_3,
  KEY_BOARD_TEXT_TYPE_DEVICE_CODE,
  KEY_BOARD_TEXT_TYPE_IP,
  KEY_BOARD_TEXT_TYPE_PAYME
} from '@/src/constants'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Keyboard: React.FC<{
  type: string
  isCaps?: boolean
  title: string
  placeHolder: string
  data: string
  length?: number
  setData: React.Dispatch<React.SetStateAction<string>>
  divRef?: React.RefObject<HTMLDivElement>
}> = ({ title, placeHolder, data, setData, divRef, isCaps = false, type }) => {
  const [keyboard, setIsKeyboard] = useState(KEY_BOARD_TEXT_TYPE_3)

  const { t } = useTranslation()

  const [isCapsLock, setIsCapsLock] = useState(isCaps)

  const KEY_BOARD = useMemo(() => {
    switch (type) {
      case 'deviceCode':
        return KEY_BOARD_TEXT_TYPE_DEVICE_CODE
      case 'payme':
        return KEY_BOARD_TEXT_TYPE_PAYME
      default:
        return KEY_BOARD_TEXT_TYPE_IP
    }
  }, [type])

  const onKeyboardClick = (key: string) => {
    switch (key) {
      case 'backspace':
        setData(data.slice(0, -1))
        break
      case 'reset':
        setData('')
        break
      case 'caps':
        setIsCapsLock(!isCapsLock)
        break
      case 'textformat':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_3)
        break
      case '!?#':
        setIsKeyboard(KEY_BOARD_TEXT_TYPE_2)
        break
      default:
        if (!length || data.length < length) {
          const newKey = isCapsLock ? key.toUpperCase() : key.toLowerCase()
          setData(data + newKey)
        }
    }
  }

  const isKeyBold = (key: string): boolean =>
    ['textformat', '!?#', 'reset', 'backspace', '̀', '́', '̉', '̃', '̣'].includes(key)

  const txtPlaceHolder = useMemo(() => {
    return type === 'IP' ? 'XXX.XXX.XXX.XXX:XXX' : t(placeHolder)
  }, [type, placeHolder])

  return (
    <>
      <div className='w-4/6 flex flex-col gap-10'>
        <p className='responsive-text-small'>{t(title)}</p>

        <div
          ref={divRef}
          className='w-full py-9 px-2.5 border-2 border-blue-500 focus:ring-blue-500 focus:border-blue-500 rounded-lg flex items-center break-all'
        >
          {data ? (
            <p className='responsive-text-small-2'>{data}</p>
          ) : (
            <p className='text-[#9ca3af] responsive-text-small-2'>{txtPlaceHolder}</p>
          )}
        </div>
      </div>

      <div className='text-center mt-10'>
        {keyboard.map((key, index) => {
          if (key === 'br') {
            return <br key={index} />
          } else if (key === 'emty') {
            return (
              <button
                type='button'
                key={index}
                className={` m-1.5 
                 bg-white w-[200px] 
                rounded-[16px] btn-custom-input mb-4 `}
              >
                <div />
              </button>
            )
          } else if (key === 'textformat') {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={` 
                 bg-white w-[129px] border-2 border-[#E5E5E5]
                 h-[100px] rounded-[16px] btn-custom-input mb-4 `}
              >
                <div className='flex items-center justify-center w-full h-full'>
                  <img className='w-[66px] h-[45px]' src={alpha} alt='alpha' />
                </div>
              </button>
            )
          } else if (key === 'caps') {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={`mr-1.5
                 bg-white w-[120px] border-2 border-[#E5E5E5]
                 h-[100px] rounded-[16px] btn-custom-input mb-4 `}
              >
                <div className='flex items-center justify-center w-full h-full'>
                  <img className='w-11 h-11' src={caps} alt='caps' />
                </div>
              </button>
            )
          } else if (key === 'backspace') {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={` ml-1.5 
             bg-[#E2E7EF] w-[120px] border-2 border-[#E5E5E5]
             h-[100px] rounded-[16px] btn-custom-input mb-4 `}
              >
                <div className='flex items-center justify-center w-full h-full'>
                  <img className='w-[58px] h-[38px]' src={remove} alt='remove' />
                </div>
              </button>
            )
          } else if (key === '!?#') {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={`w-[129px] m-1.5 
                 bg-[#E2E7EF]
                 h-[100px] rounded-[16px] btn-custom-input mb-4`}
              >
                <p className='responsive-text-small'>{isCapsLock ? key.toUpperCase() : key.toLowerCase()}</p>
              </button>
            )
          } else {
            return (
              <button
                type='button'
                onClick={() => onKeyboardClick(key)}
                key={index}
                className={`${key === 'space' ? 'w-[240px]' : key === 'gmail.com' ? 'w-[180px]' : 'w-[82px]'} m-1.5 
                  ${isKeyBold(key) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} 
                 h-[100px] rounded-[16px] btn-custom-input mb-4`}
              >
                {key === 'reset' && <Icon title='clear' />}
                {key === 'space' && <Icon title='space_bar' />}

                {key !== 'reset' &&
                  key !== 'textformat' &&
                  key !== 'gmail.com' &&
                  key !== '.com' &&
                  key !== '.vn' &&
                  key !== 'space' && (
                    <p className='responsive-text-small'>{isCapsLock ? key.toUpperCase() : key.toLowerCase()}</p>
                  )}
              </button>
            )
          }
        })}
      </div>
    </>
  )
}

export default Keyboard

//viewChildren

const Icon: React.FC<any> = ({ title }) => {
  return <i className='material-icons'>{title}</i>
}
